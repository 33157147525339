<template>
  <div>
    <VContainer class="outer">
      <VRow>
        <VCol cols="1" />
        <VCol
          cols="10"
          class="no-after"
        >
          <VRow class="inner">
            <VCol />
            <VCol />
            <VCol />
            <VCol />
            <VCol />
            <VCol />
            <VCol />
            <VCol />
            <VCol />
            <VCol />
            <VCol />
            <VCol />
          </VRow>
        </VCol>
        <VCol cols="1" />
      </VRow>
    </VContainer>
  </div>
</template>

<script>
export default {
  name: 'PlatformGrid',
};
</script>

<style scoped lang="scss">
.container {
  background: map-get($tt-light-red, 'pale');
  position: relative;

  &.outer {
    height: 100vh;
  }

  &.inner {
    height: 100%;
  }

  &:not(:last-child) {
    border-bottom: 1px solid map-get($tt-light-mono-100, 'base');
  }

  &::before {
    content: 'container';
    position: absolute;
    left: 0;
    top: 0;
    text-align: left;
    z-index: 9997;
    background: map-get($tt-light-red, 'pale');
    border-bottom: 1px solid map-get($tt-light-mono-100, 'base');
    padding: 12px;
  }
}

.row {
  background: map-get($tt-light-mono-0, 'base');
  border-top: 1px dotted map-get($tt-light-mono-100, 'base');
  border-bottom: 1px dotted map-get($tt-light-mono-100, 'base');
  height: 100%;
  position: relative;

  &::before {
    content: 'row';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    text-align: left;
    z-index: 9998;
    background: map-get($tt-light-mono-0, 'base');
    border-top: 1px dotted map-get($tt-light-mono-100, 'base');
    border-bottom: 1px dotted map-get($tt-light-mono-100, 'base');
    padding: 12px;
  }
}

.col {
  background: map-get($tt-light-mono-0, 'base');
  border-right: 1px dashed map-get($tt-light-mono-100, 'base');
  height: 100%;
  position: relative;

  &:first-child {
    border-left: 1px dashed map-get($tt-light-mono-100, 'base');
  }

  &::before {
    content: 'col';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    z-index: 9999;
    background: map-get($tt-light-green, 'pale');
    border-left: 1px dashed map-get($tt-light-mono-100, 'base');
    border-right: 1px dashed map-get($tt-light-mono-100, 'base');
    padding: 12px;
  }

  &:not(.no-after)::after {
    content: '';
    display: block;
    background: map-get($tt-light-green, 'pale');
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    width: 100%;
  }
}

.outer .col:not(.no-after)::after {
  content: '';
  display: block;
  background: map-get($tt-light-green, 'pale');
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
}

.inner .col:not(.no-after)::after {
  content: '';
  display: block;
  background: map-get($tt-light-blue, 'pale');
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
}

.inner .col::before {
  content: 'col';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  z-index: 9999;
  background: map-get($tt-light-blue, 'pale');
  border-left: 1px dashed map-get($tt-light-mono-100, 'base');
  border-right: 1px dashed map-get($tt-light-mono-100, 'base');
  padding: 12px;
}
</style>
